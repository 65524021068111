import * as constants from './constants';

const defaultState = {
	data: {
		clients: []
	}
};

export const clientReducer = (state = defaultState, action) => {
	switch (action.type) {
		case constants.CREATE_CLIENT :
			state = {
				...state,
				data: {
					...state.data,
					clients: [
						...state.data.clients,
						{
							id: action.payload.id,
							name: '',
							address: '',
							zipcity: '',
							phone: '',
							email: '',
							whatsWrong: '',
							howToHandle: '',
							important: '',
							confidentialAdvisers: '',
							confidentialAdvisersAgreements: '',
							medicalInformation: '',
						}
					]
				}
			};
			break;
		case constants.UPDATE_CLIENT :
			state = {
				...state,
				data: {
					...state.data,
					clients: [
						...state.data.clients.map(client => {
							if ( client.id !== action.payload.id ) {
								return client;
							}

							return {
								...client,
								[action.payload.key]: action.payload.value
							}
						})
					]
				}
			};

			break;
		case constants.DELETE_CLIENT :
			state = {
				...state,
				data: {
					...state.data,
					clients: [
						...state.data.clients.filter( client => {
							return client.id !== action.payload.id;
						})
					]
				}
			};
			break;
		default :
			state = defaultState;
			break;
	}

	return state;
};