import React, {Suspense} from "react";
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';

import {
	BrowserRouter as Router
} from 'react-router-dom';

ReactDOM.render(
	<React.StrictMode>
		<Suspense fallback={<div>Aan het laden...</div>}>
			<Router>
				<App/>
			</Router>
		</Suspense>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
