import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from 'react-redux';
import React, {lazy, Component} from "react";
import logo from './assets/images/logo.png';
import {store, persistor} from './reducers';
import './assets/scss/init.scss';
import * as serviceWorker from './serviceWorker';


import {
	Link,
	Route,
	Switch,
} from 'react-router-dom';

const HomeComponent = lazy(() => import('./modules/home'));
const FormComponent = lazy(() => import('./modules/form'));


// ... normal setup, create store and persistor, import components etc.


class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			newVersionAvailable: false,
			waitingWorker: {}
		}
	}

	onServiceWorkerUpdate = (registration) => {
		this.setState({
			waitingWorker: registration && registration.waiting,
			newVersionAvailable: true,
		});
	};

	updateServiceWorker = () => {
		const {waitingWorker} = this.state;
		waitingWorker && waitingWorker.postMessage({type: "SKIP_WAITING"});
		this.setState({newVersionAvailable: false});
		window.location.reload();
	};

	componentDidMount = () => {
		if (process.env.NODE_ENV === 'production') {
			serviceWorker.register({onUpdate: this.onServiceWorkerUpdate});
		}
	};

	render() {

		const updateModalClasses = [
			'update-modal'
		];

		if (this.state.newVersionAvailable) {
			updateModalClasses.push('update-modal--visible');
		}

		return (
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<div className="grid-container">
						<div className="grid-x grid-margin-y grid-padding-y">
							<div className="cell">
								<div className="align-center grid-x grid-margin-y">
									<div className="cell shrink">
										<Link to={'/'}>
											<img src={logo} alt="" style={{maxWidth: 150}}/>
										</Link>
									</div>

									<div className="cell">
										<Switch>
											<Route exact path={'/'} component={HomeComponent}/>
											<Route exact path={'/:clientID'} component={FormComponent}/>
										</Switch>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className={updateModalClasses.join(' ')}>
						<div className="grid-container">
							<div className="grid-x grid-margin-x grid-margin-y grid-padding-y">
								<div className="cell">
									<div className="update-modal--inner" onClick={this.updateServiceWorker}>
										Er is een update beschikbaar. Klik om te updaten.
									</div>
								</div>
							</div>
						</div>
					</div>


				</PersistGate>
			</Provider>
		);
	}
}

export default App;