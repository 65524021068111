// configureStore.js

import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import { clientReducer } from './client'

const persistConfig = {
	key: 'root',
	storage,
};

const reducers = combineReducers({
	clients: clientReducer
})

const persistedReducer = persistReducer(persistConfig, reducers);

let store = createStore(persistedReducer);
let persistor = persistStore(store);

export {
	store,
	persistor,
}